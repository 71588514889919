// app.js
import React from "react";
import "./App.css";
import Nav from "./components/Nav.tsx";
import Header from "./components/Header.tsx";
import AboutMe from "./components/AboutMe.tsx";
import Portfolio from "./components/Portfolio.tsx";
import Contacto from "./components/Contacto.tsx";

function App() {
    //declaro todos mis proyectos aca
    const projects = [
        {
            id: 1,
            title: "Sin Fronteras",
            image: "/projects/sinfronteras.jpg",
            description: "project1.description",
            technologies: ["React", "Javascript", "Node.js", "MongoDB"],
            demoLink: "https://cookie-frontend-3ojs.onrender.com",
            githubLink:
                "https://github.com/matiasiaquinta/test-cookie-frontend",
        },
        {
            id: 2,
            title: "Fusionar IT",
            image: "/projects/fusionarit.jpg",
            description: "project2.description",
            technologies: [
                "HTML",
                "CSS",
                "Javascript",
                "React",
                "Figma",
                "Illustrator",
                "Photoshop",
            ],
            demoLink: "https://fusionarit.com.ar",
            githubLink: "https://github.com/matiasiaquinta/FusionarIT",
            pdfLink: "FusionarIT.pdf",
        },
        {
            id: 3,
            title: "Pixie",
            image: "/projects/pixie.jpg",
            description: "project3.description",
            technologies: ["HTML", "CSS", "Javascript", "PHP", "MySQL"],
            demoLink: "https://matiasiaquinta.com.ar/demos/pixie/",
            githubLink: "https://github.com/matiasiaquinta/pixie",
        },
        {
            id: 4,
            title: "project4.title",
            image: "/projects/puzzle.jpg",
            description: "project4.description",
            technologies: ["Unity 3D", "Illustrator", "Figma"],
            demoLink: "https://matiasiaquinta.com.ar/demos/puzzle/",
            githubLink: "https://github.com/matiasiaquinta/Puzzle-Bariloche",
        },

        // Agrega más proyectos según sea necesario
    ];

    return (
        <div className="App">
            <div className="lg:h-screen z-10">
                {" "}
                {/* 100vh en resoluciones lg o mas altas */}
                <Nav />
                <Header />
            </div>

            <div>
                <AboutMe />

                <Portfolio projects={projects} />

                <Contacto />
            </div>
        </div>
    );
}

export default App;
