import React from 'react'

//language
import { useTranslation } from 'react-i18next';

interface Project {
    id: number;
    title: string;
    image: string;
    description: string;
    technologies: string[];
    demoLink: string;
    githubLink?: string;
    pdfLink?: string;
    //? -> es opcional
}
  
interface PortfolioProps {
    projects: Project[];
}
  

const Portfolio: React.FC<PortfolioProps> = ({ projects }) => {

    //language
    const { t } = useTranslation();

  return (
    <div id="proyectos" className="py-16 w-10/12 m-auto lg:w-full lg:py-36">
        <p className="lg:w-1/2 m-auto uppercase py-2 px-10 text-center rounded-lg bg-black text-yellow-400 font-['poppins'] font-bold text-xl">Portfolio</p>
        <div>
            {projects.map((project) => (
                <div key={project.id} className="mt-16 flex flex-col items-center lg:flex-row lg:w-4/5 lg:mt-16 lg:m-auto">
                    <div className="w-full mr-0 lg:w-1/2 lg:mr-12">
                        <img src={project.image} alt={project.title} width={700} className="m-auto rounded-2xl shadow-2xl shadow-black/50" />
                    </div>
                    
                    <div className="w-10/12 py-10 lg:py-0 lg:w-1/2 ">
                        <h2 className="uppercase font-['poppins'] text-2xl font-bold mb-4 text-colorSubtitle">{t(project.title)}</h2>
                        <p className="text-left text-colorText font-['helvetica']">{t(project.description)}</p>
                        <div className="flex flex-wrap gap-y-2 mt-4 justify-center">
                            {project.technologies.map((tech) => (
                                <span key={tech} className="mr-2 px-2 py-1 border-2 font-['helvetica'] border-black rounded text-sm shadow-lg shadow-black/30">{tech}</span>
                            ))}
                        </div>
                       
                        <div className="mt-8 flex justify-around w-lg">
                            <a href={project.demoLink} target="_blank" rel="noopener noreferrer" className="text-black font-bold border-2 font-['helvetica'] border-black px-4 py-2 rounded flex items-center hover:bg-yellow-400 shadow-lg shadow-black/30">
                                Demo
                                <img src="newwindow.svg" alt="New Link Icon" className="ml-2 w-6 h-6" />
                            </a>
                            {project.pdfLink ? (
                                <>
                                    <a href={project.pdfLink} target="_blank" rel="noopener noreferrer" className="text-black font-bold border-2 font-['helvetica'] border-black px-4 py-2 rounded flex items-center hover:bg-yellow-400 shadow-lg shadow-black/30">
                                        PDF
                                        <img src="software/pdf.svg" alt="New Link Icon" className="ml-2 w-6 h-6" />
                                    </a>
                                    <a href={project.githubLink} target="_blank" rel="noopener noreferrer" className="text-black font-bold border-2 font-['helvetica'] border-black px-4 py-2 rounded flex items-center hover:bg-yellow-400 shadow-lg shadow-black/30">
                                        Code
                                        <img src="software/github.svg" alt="GitHub Logo" className="ml-2 w-6 h-6" />
                                    </a>
                                </>
                            ) : (
                                <a href={project.githubLink} target="_blank" rel="noopener noreferrer" className="text-black font-bold border-2 font-['helvetica'] border-black px-4 py-2 rounded flex items-center hover:bg-yellow-400 shadow-lg shadow-black/30">
                                    Code
                                    <img src="software/github.svg" alt="GitHub Logo" className="ml-2 w-6 h-6" />
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Portfolio
