import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//import i18n from "./i18n";
//import Magnet from "./Magnet";
import { scrollToElement } from "../utils/scrollTo";

// Esto es para el hamburguer oculto
const handleNavClick = (id, toggleMenu) => (e) => {
    e.preventDefault();
    toggleMenu();
    scrollToElement(id);
};

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    //language
    const { t, i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState("");

    useEffect(() => {
        setCurrentLanguage(i18n.language);
    }, [i18n.language]);

    const changeLanguage = () => {
        const newLanguage = currentLanguage === "en" ? "es" : "en";
        setCurrentLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    return (
        <div className="bg-white shadow-xl text-black p-6 flex justify-between items-center fixed top-0 left-0 w-full z-50">
            <h3 className="font-['poppins'] md:mr-4 md:ml-10 font-bold text-2xl text-colorTitle hover:text-shadow shadow-yellow-400">
                <span className="font-['asd']">M</span>atias{" "}
                <span className="font-['asd'] pl-1">I</span>aquinta
            </h3>

            <ul className="hidden md:flex space-x-8 mr-10 font-['poppins'] font-semibold text-colorTitle">
                <li className="hover:text-shadow shadow-yellow-400 transition duration-300 transform hover:translate-y-1">
                    <a
                        href="#home"
                        onClick={(e) => {
                            e.preventDefault();
                            scrollToElement("home");
                        }}
                    >
                        <span className="font-['asd']">{t("I")}</span>
                        {t("nicio")}
                    </a>
                </li>
                <li className="hover:text-shadow shadow-yellow-400 transition duration-300 transform hover:translate-y-1">
                    <a
                        href="#sobre"
                        onClick={(e) => {
                            e.preventDefault();
                            scrollToElement("sobre");
                        }}
                    >
                        <span className="font-['asd']">{t("S")}</span>
                        {t("obre")}
                    </a>
                </li>
                <li className="hover:text-shadow shadow-yellow-400 transition duration-300 transform hover:translate-y-1">
                    <a
                        href="#proyectos"
                        onClick={(e) => {
                            e.preventDefault();
                            scrollToElement("proyectos");
                        }}
                    >
                        <span className="font-['asd']">{t("P")}</span>
                        {t("royectos")}
                    </a>
                </li>
                <li className="hover:text-shadow shadow-yellow-400 transition duration-300 transform hover:translate-y-1">
                    <a
                        href="#contacto"
                        onClick={(e) => {
                            e.preventDefault();
                            scrollToElement("contacto");
                        }}
                    >
                        <span className="font-['asd']">{t("C")}</span>
                        {t("ontacto")}
                    </a>
                </li>
                <li>
                    <div className="h-full w-[0.1rem] bg-gray-800"></div>
                </li>

                <li>
                    <button
                        onClick={changeLanguage}
                        className="hover:text-shadow shadow-yellow-400 rounded-md px-2 font-['poppins'] transition duration-300 transform hover:translate-y-1"
                        title={t("Haz clic para cambiar de idioma")}
                    >
                        {currentLanguage.toUpperCase()}
                    </button>
                </li>
            </ul>

            <div className="md:hidden inline-flex">
                <button
                    onClick={changeLanguage}
                    className="text-black hover:text-yellow-400 hover:bg-black rounded-md px-4 mr-4 sm:mr-10 border-2 border-black font-bold"
                >
                    {currentLanguage.toUpperCase()}
                </button>
                <button
                    onClick={toggleMenu}
                    className="flex items-center px-3 py-2 border rounded text-black border-black"
                >
                    {isOpen ? (
                        <svg
                            className="fill-current h-5 w-5"
                            viewBox="0 0 20 20"
                        >
                            <title>Cerrar</title>
                            <path d="M6.293 6.293a1 1 0 0 1 1.414 0L10 8.586l2.293-2.293a1 1 0 1 1 1.414 1.414L11.414 10l2.293 2.293a1 1 0 0 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 0-1.414z" />
                        </svg>
                    ) : (
                        <svg
                            className="fill-current h-5 w-5"
                            viewBox="0 0 20 20"
                        >
                            <title>Menu</title>
                            <path d="M0 3h20v2H0zM0 9h20v2H0zM0 15h20v2H0z" />
                        </svg>
                    )}
                </button>
            </div>
            {isOpen && (
                <div className="fixed top-20 left-0 w-full h-full bg-white z-50 flex justify-center items-center transition-transform duration-300">
                    <div className="flex flex-col font-['poppins'] text-3xl gap-16 text-black pb-32">
                        <a
                            href="#home"
                            onClick={handleNavClick("home", toggleMenu)}
                        >
                            {t("Inicio")}
                        </a>
                        <a
                            href="#sobre"
                            onClick={handleNavClick("sobre", toggleMenu)}
                        >
                            {t("Sobre")}
                        </a>
                        <a
                            href="#proyectos"
                            onClick={handleNavClick("proyectos", toggleMenu)}
                        >
                            {t("Proyectos")}
                        </a>
                        <a
                            href="#contacto"
                            onClick={handleNavClick("contacto", toggleMenu)}
                        >
                            {t("Contacto")}
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Navbar;
