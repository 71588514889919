import React, { useState, useEffect } from "react";
import {
    FaArrowUp,
    FaWhatsapp,
    FaLinkedin,
    FaGithub,
    FaBehance,
} from "react-icons/fa";
import { SiGmail } from "react-icons/si";

//language
import { useTranslation } from "react-i18next";
import i18n from "./i18n";

const Contacto = () => {
    //language
    const { t } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    //button going up
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    return (
        <div
            id="contacto"
            className="relative mt-20 gap-4 flex flex-col justify-between p-4 bg-white shadow-[0_-4px_6px_1px_rgba(0,0,0,0.2),0_-2px_4px_-1px_rgba(0,0,0,0.06)]"
        >
            <div className="text-center">
                <p className="text-xl text-colorSubtitle font-bold font-['poppins']">
                    {t("Contactame")}
                </p>
                <p className="pt-2 text-colorSubtitle font-['helvetica']">
                    {t(
                        "Para cualquier consulta, no dudes en contactarme a través de los siguientes medios:"
                    )}
                </p>
            </div>
            <div className="flex flex-col items-center space-y-2 font-['helvetica']">
                <div className="flex items-center space-x-2">
                    <SiGmail className="text-red-500" />
                    <p>matias.iaquinta@gmail.com</p>
                </div>
                <div className="flex items-center space-x-2">
                    <FaWhatsapp className="text-green-500" />
                    <p>
                        +54 <span className="font-bold">-</span> 11 6282 - 9588
                    </p>
                </div>
                <div className="flex items-center space-x-4">
                    <a
                        href="https://www.linkedin.com/in/matiasiaquinta/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-700"
                    >
                        <FaLinkedin size={24} />
                    </a>
                    <a
                        href="https://github.com/matiasiaquinta"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-800"
                    >
                        <FaGithub size={24} />
                    </a>
                </div>
            </div>
            <button
                className={`fixed bottom-4 right-4 bg-black text-yellow-400 p-3 rounded-full shadow-lg transition duration-300 hover:opacity-100 ${
                    isVisible ? "opacity-50" : "opacity-0"
                }`}
                onClick={scrollToTop}
            >
                <FaArrowUp />
            </button>
            <footer className="text-center mt-4">
                <p className="text-colorText font-['poppins'] text-sm">
                    Copyright &copy; 2024. {t("Todos los derechos reservados.")}
                </p>
            </footer>
        </div>
    );
};

export default Contacto;
