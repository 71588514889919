import React from "react";

//language
import { useTranslation } from "react-i18next";
import Magnet from "./Magnet";
//import i18n from "./i18n";

const AboutMe = () => {
    //language
    const { t } = useTranslation();

    //const changeLanguage = (lng) => {
    //    i18n.changeLanguage(lng);
    //};

    return (
        <div
            id="sobre"
            className="bg-white pt-20 pb-40 flex flex-col justify-center items-center gap-14 lg:flex-row"
        >
            <div className="w-10/12 lg:overflow-hidden lg:w-[500px] lg:h-[500px] shadow-2xl shadow-black/50 rounded-xl">
                <img
                    src="tesis.jpg"
                    alt="Tesis Videojuego"
                    className="w-full h-full object-cover object-right rounded-xl"
                />
            </div>
            <div className="text-left w-10/12 lg:max-w-xl">
                <p className="uppercase py-2 px-10 text-center rounded-lg bg-black text-yellow-400 font-['poppins'] font-bold text-xl">
                    {t("Sobre Mi")}
                </p>
                <p className="font-['helvetica'] font-bold text-base text-colorSubtitle mt-6">
                    {t(
                        "Siempre estoy aprendiendo porque la tecnología nunca para de avanzar."
                    )}
                </p>
                <div className="mt-2 text-colorText text-base font-['helvetica']">
                    <p className="mb-2">{t("parrafo01")}</p>
                    <p className="mb-2">{t("parrafo02")}</p>
                    <p className="mb-2">{t("parrafo03")}</p>

                    <div className="lg:flex lg:flex-row lg:justify-around mt-8">
                        <div className="flex flex-col md:flex-row gap-12 justify-center items-center">
                            <Magnet>
                                <a
                                    href="/cvespanol.pdf"
                                    download
                                    className="bg-blue-600 text-white px-12 py-3 text-sm rounded-md hover:bg-blue-700 transition duration-300"
                                >
                                    {t("Español")}
                                </a>
                            </Magnet>
                            <h2 className="font-['poppins'] font-bold text-xl text-black">
                                {t("DESCARGAR CV")}
                            </h2>
                            <Magnet>
                                <a
                                    href="/cvingles.pdf"
                                    download
                                    className="bg-blue-600 text-white px-12 py-3 text-sm rounded-md hover:bg-blue-700 transition duration-300"
                                >
                                    {t("Inglés")}
                                </a>
                            </Magnet>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutMe;
