import { useState, useRef, useEffect } from "react";

const Magnet = ({ children, padding = 60, disabled = false }) => {
    const [isActive, setIsActive] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const magnetRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Detectar si es un dispositivo móvil
        const handleResize = () => {
            const isMobileDevice =
                window.matchMedia("(max-width: 768px)").matches;
            setIsMobile(isMobileDevice);
        };

        // Escuchar cambios de tamaño de pantalla
        handleResize(); // Ejecutar al montar
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (disabled || isMobile) {
            // Resetear posición si está deshabilitado o si es móvil
            setPosition({ x: 0, y: 0 });
            return;
        }

        const handleMouseMove = (e) => {
            if (magnetRef.current) {
                const { left, top, width, height } =
                    magnetRef.current.getBoundingClientRect();
                const centerX = left + width / 2;
                const centerY = top + height / 2;
                const distX = Math.abs(centerX - e.clientX);
                const distY = Math.abs(centerY - e.clientY);

                // Verificar si el mouse está dentro del área de padding
                if (
                    distX < width / 2 + padding &&
                    distY < height / 2 + padding
                ) {
                    setIsActive(true);
                    // Calcular desplazamiento
                    const offsetX = (e.clientX - centerX) / 4;
                    const offsetY = (e.clientY - centerY) / 4;
                    setPosition({ x: offsetX, y: offsetY });
                } else {
                    setIsActive(false);
                    setPosition({ x: 0, y: 0 });
                }
            }
        };

        // Escuchar eventos de mouse
        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, [padding, disabled, isMobile]);

    return (
        <div
            ref={magnetRef}
            style={{ position: "relative", display: "inline-block" }}
        >
            <div
                style={{
                    transform: `translate3d(${position.x}px, ${position.y}px, 0)`,
                    transition: isActive
                        ? "transform 0.3s ease-out"
                        : "transform 0.5s ease-in-out",
                    willChange: "transform",
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default Magnet;
