import React from "react";

//icons
import { FaGithub, FaLinkedinIn } from "react-icons/fa6";

//language
import { useTranslation } from "react-i18next";
import Magnet from "./Magnet";
//import i18n from "./i18n";

const Header = () => {
    //language
    const { t } = useTranslation();

    //const changeLanguage = (lng) => {
    //    i18n.changeLanguage(lng);
    //};

    return (
        <div
            id="home"
            className="w-10/12 m-auto pt-40 flex flex-col-reverse justify-center items-center gap-y-20 h-full lg:flex-row lg:pt-8 lg:gap-x-40"
        >
            {/* Sección de información e íconos */}
            <div className="flex flex-col max-w-3xl text-left">
                <div className="relative">
                    <h1 className="font-['poppins'] font-bold text-4xl md:text-5xl text-center text-colorSubtitle max-w-2xl lg:text-6xl lg:text-left">
                        {t("Licenciado en")} <br></br>
                        {t("Diseño Multimedial")}
                    </h1>
                    <img
                        src="graduate.svg"
                        alt="Graduate Logo"
                        className="absolute w-16 h-16 -top-12 right-0 lg:-top-12 lg:-right-4 lg:w-24 lg:h-24"
                    />
                </div>
                <p className="font-['helvetica'] text-colorText text-base mt-3 text-center lg:text-left">
                    {t("Hola! soy Matias de Buenos Aires, Argentina.")}
                </p>
                <p className="font-['helvetica'] text-colorText text-base text-center lg:text-left">
                    {t("Me especializo en")}{" "}
                    <span className="font-['helvetica'] font-bold text-black text-lg bg-yellow-200 inline-block rounded-md px-2">
                        {t("Frontend Developer")}
                    </span>
                </p>

                <ul className="mt-5 flex gap-5 justify-center lg:justify-start">
                    <Magnet>
                        <li className="cursor-pointer text-3xl transition duration-500 ease-in-out transform hover:scale-150">
                            <a
                                href="https://www.linkedin.com/in/matiasiaquinta/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-black hover:text-blue-700"
                            >
                                <FaLinkedinIn />
                            </a>
                        </li>
                    </Magnet>
                    <Magnet>
                        <li className="cursor-pointer text-3xl transition duration-500 ease-in-out transform hover:scale-150">
                            <a
                                href="https://github.com/matiasiaquinta"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-black hover:text-gray-700"
                            >
                                <FaGithub />
                            </a>
                        </li>
                    </Magnet>
                </ul>

                <div className="mt-10 text-center w-10/12 m-auto md:w-full lg:flex lg:items-center lg:justify-center lg:gap-20 lg:max-w-xl">
                    <div className="font-['poppins'] font-bold mb-5 lg:w-2 lg:mr-8 lg:mb-0">
                        {t("Destaco")}
                    </div>

                    <div className="hidden h-12 w-[0.1rem] bg-gray-800 mx-auto lg:block"></div>
                    <ul className="flex justify-center gap-3">
                        <li className="transition duration-200 ease-in-out transform hover:scale-125">
                            <img
                                src="/software/react.svg"
                                alt="React"
                                width={50}
                                height={50}
                            />
                        </li>
                        <li className="transition duration-200 ease-in-out transform hover:scale-125">
                            <img
                                src="/software/html.svg"
                                alt="HTML5"
                                width={50}
                                height={50}
                            />
                        </li>
                        <li className="transition duration-200 ease-in-out transform hover:scale-125">
                            <img
                                src="/software/css.svg"
                                alt="CSS3"
                                width={50}
                                height={50}
                            />
                        </li>
                        <li className="transition duration-200 ease-in-out transform hover:scale-125">
                            <img
                                src="/software/js.svg"
                                alt="Javascript"
                                width={50}
                                height={50}
                            />
                        </li>
                        <li className="transition duration-200 ease-in-out transform hover:scale-125">
                            <img
                                src="/software/figma.svg"
                                alt="Figma"
                                width={50}
                                height={50}
                            />
                        </li>
                        <li className="transition duration-200 ease-in-out transform hover:scale-125">
                            <img
                                src="/software/ai.svg"
                                alt="Adobe Illustrator"
                                width={50}
                                height={50}
                            />
                        </li>
                        <li className="transition duration-200 ease-in-out transform hover:scale-125">
                            <img
                                src="/software/ps.svg"
                                alt="Adobe Photoshop"
                                width={50}
                                height={50}
                            />
                        </li>
                    </ul>
                </div>
            </div>

            {/* Sección de imagen */}
            <div className="relative group mt-10 lg:mt-0">
                <div className="bg-black rounded-t-full h-full absolute inset-0 transition-shadow duration-500 ease-in-out group-hover:shadow-[0_40px_100px_0_rgba(0,0,0,0.7)]"></div>
                <div className="w-60 h-56 rounded-[80px] border-8 border-black bg-white relative z-10">
                    <img
                        src="/matias.png"
                        alt="Imagen Personal"
                        className="absolute inset-0 object-cover transform -translate-y-[5.6rem] duration-500 ease-in-out group-hover:-translate-y-[5.2rem]"
                    />
                </div>
            </div>
        </div>
    );
};

export default Header;
