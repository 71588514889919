// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import HttpApi from 'i18next-http-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {
            //NAV
            Welcome: "Welcome",
            I: "H",
            nicio: "ome",
            S: "A",
            obre: "bout",
            P: "P",
            royectos: "rojects",
            C: "C",
            ontacto: "ontact",
            Inicio: "Home",
            Sobre: "About",
            Proyectos: "Projects",
            Contacto: "Contact",
            "Haz clic para cambiar de idioma": "Click to change language",
            //HEADER
            "Licenciado en": "Graduate in",
            "Diseño Multimedial": "Multimedial Design",
            "Hola! soy Matias de Buenos Aires, Argentina.":
                "Hello! I'm Matias de Buenos Aires, Argentina.",
            "Me especializo en": "I specialize in",
            "Full Stack Developer": "Full Stack Developer",
            Destaco: "Highlight",
            //ABOUTME
            "Sobre Mi": "About Me",
            "Siempre estoy aprendiendo porque la tecnología nunca para de avanzar.":
                "I'm always learning because technology never stops advancing.",
            parrafo01:
                "I graduated in 2021 and since then I have been learning and improving my skills with new technologies and tools. My thesis involved developing a virtual reality game for children aged 8 to 12, using Unity3D. The image shown is a screenshot of my game.",
            parrafo02:
                "I started my career as a graphic designer, creating visual pieces. Then, I dove into web design, focusing on interface, usability, and user experience design. As I progressed, I began to develop my own projects and entered the world of programming.",
            parrafo03:
                "I am currently working as a freelancer, but I am open to job proposals. I have experience as a Full Stack Developer and am excited to keep learning and growing professionally.",
            Español: "Spanish",
            "DESCARGAR CV": "DOWNLOAD CV",
            Inglés: "English",
            //PORTFOLIO
            //"project1.title": "Fusionar IT",
            "project1.description":
                "I developed a web application for managing a sports club called Sin Fronteras. It is designed for mobile use and allows for creating students, recording payments, generating monthly income reports, and other specific functionalities requested. (Demo -> Email: test@gmail.com / Password: test123).",
            "project2.description":
                "This project is for my consultancy specializing in digital services, focused on turning ideas into innovative solutions. From design to implementation, it has been a great opportunity to learn and grow in web development and graphic design. The PDF outlines the brand’s visual identity, including the meaning and components of the logo.",
            //"project2.title": "",
            "project3.description":
                "I developed a program for managing equipment in a repair workshop. The application allows for adding equipment and tracking its status. Initially designed for the web, it was exported to an app to meet the client's need for rapid implementation.",
            "project4.title": "Puzzle",
            "project4.description":
                "Puzzle video game designed for a particular tourism event for Bariloche, Entre Ríos and Tucumán. The system was designed to be used on a vertical touch screen, where users passed by the stand to play.",
            "": "",
            //CONTACTO
            Contactame: "Contact Me",
            "Para cualquier consulta, no dudes en contactarme a través de los siguientes medios:":
                "For any questions, do not hesitate to contact me through the following means:",
            "Todos los derechos reservados.": "All rights reserved.",
            //"": "",
            //"": "",
            //"": "",
            //"": "",
        },
    },
    es: {
        translation: {
            //NAV
            Welcome: "Bienvenido",
            I: "I",
            nicio: "nicio",
            S: "S",
            obre: "obre",
            P: "P",
            royectos: "royectos",
            C: "C",
            ontacto: "ontacto",
            Inicio: "Inicio",
            Sobre: "Sobre",
            Proyectos: "Proyectos",
            Contacto: "Contacto",
            "Haz clic para cambiar de idioma":
                "Haz clic para cambiar de idioma",
            //HEADER
            "Licenciado en": "Licenciado en",
            "Diseño Multimedia": "Diseño Multimedia",
            "Hola! soy Matias de Buenos Aires, Argentina.":
                "Hola! soy Matias de Buenos Aires, Argentina.",
            "Me especializo en Front-End Developer.":
                "Me especializo en Front-End Developer.",
            Destaco: "Destaco",
            //ABOUTME
            "Sobre Mi": "Sobre Mi",
            "Siempre estoy aprendiendo porque la tecnología nunca para de avanzar.":
                "Siempre estoy aprendiendo porque la tecnología nunca para de avanzar.",
            parrafo01:
                "Me gradué en 2021 y desde entonces he estado aprendiendo y mejorando mis habilidades con nuevas tecnologías y herramientas. Mi tesis consistió en desarrollar un videojuego de realidad virtual para niños de 8 a 12 años, utilizando Unity3D. La imagen que esta es una captura de mi videojuego.",
            parrafo02:
                "Comencé mi carrera como diseñador gráfico, creando piezas visuales. Luego, me sumergí en el diseño web, enfocándome en el diseño de interfaces, usabilidad y experiencia de usuario. A medida que progresaba empecé a desarrollar mis propios proyectos y me adentré en el mundo de la programación.",
            parrafo03:
                "Actualmente estoy desarrollando trabajos de manera freelance, pero estoy abierto a recibir propuestas de trabajo. Tengo experiencia en el área de Full Stack Developer y estoy con muchas ganas de seguir aprendiendo y creciendo profesionalmente.",
            Español: "Español",
            "DESCARGAR CV": "DESCARGAR CV",
            Inglés: "Inglés",
            //PORTFOLIO
            "project1.description":
                "Desarrollé una aplicación web para el seguimiento en un club de deportes llamado Sin Fronteras. Está diseñada para uso móvil y permite crear alumnos, registrar pagos, generar informes mensuales de ingresos y otras funcionalidades específicas solicitadas. (Demo -> Email: test@gmail.com / Password: test123).",
            "project2.title": "Fusionar IT",
            "project2.description":
                "Este proyecto es para mi consultora especializada en servicios digitales, enfocada en transformar ideas en soluciones innovadoras. Desde el diseño hasta la implementación, ha sido una gran oportunidad para aprender y crecer en desarrollo web y diseño gráfico. En el PDF se detalla la identidad visual de la marca, incluyendo el significado y los componentes del logo.",
            //"project2.title": "",
            "project3.description":
                "Desarrollé un programa para la gestión de equipos en un taller de reparación. La aplicación facilita la adición de equipos y el seguimiento de su estado. Inicialmente diseñada para la web, se exportó a una aplicación para cumplir con la necesidad de rápida implementación del cliente.",
            "project4.title": "Rompecabeza",
            "project4.description":
                "Videojuego de puzzle diseñado para un evento particular de turismo para Bariloche, Entre Ríos y Tucumán. Se pensó el sistema para ser utilizado en una pantalla vertical táctil, donde los usuarios pasaban por el stand a jugar.",
            "": "",
            //CONTACTO
            Contactame: "Contactame",
            "Para cualquier consulta, no dudes en contactarme a través de los siguientes medios:":
                "Para cualquier consulta, no dudes en contactarme a través de los siguientes medios:",
            "Todos los derechos reservados.": "Todos los derechos reservados.",
            //"": "",
            //"": "",
        },
    },
};

i18n
    //.use(HttpApi)
    //.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        supportedLngs: ["en", "es"],
        fallbackLng: "es", // Español por defecto
        detection: {
            order: [
                "path",
                "cookie",
                "htmlTag",
                "localStorage",
                "sessionStorage",
                "navigator",
                "subdomain",
            ],
            caches: ["cookie"],
        },
        backend: {
            loadPath: "/locales/{{lng}}/translation.json",
        },
        react: { useSuspense: false },
    });

export default i18n;
